import { Component, Input, OnInit } from '@angular/core';
import { Event } from 'src/app/core/data/event/event.model';

@Component({
  selector: 'app-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss'],
})
export class EventCardComponent implements OnInit {
  @Input()
  public event: Event;

  constructor() {}

  ngOnInit(): void {}

  public image(): string {
    return this.event.imageUrl ?? 'https://via.placeholder.com/213x254';
  }
}
