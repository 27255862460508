import { Type } from 'class-transformer';
import { Address } from '../address/address.model';
import { Model } from '../base.model';
import { Event } from '../event/event.model';
import { Media } from '../media/media.model';

class Info {
  public id: number;
  public imageUrl: string;
  public url: string;
  public description: string;
  public order: number;

  public get imageInfoUrl(): string {
    return this.imageUrl.replace('remateweb.s3.amazonaws.com', 'd13nls57a1x77f.cloudfront.net');
  }
}

export class Partner {
  public userId: string;

  public partnerAboutTitle: string;

  public partnerText: string;

  public partnerTitle: string;

  public partnerVideoId: string;

  public userInfos: Info[];

  public partnerLogo: string;

  public partnerBanner: string;

  public country: string;

  public state: string;

  public city: string;

  public get address(): string {
    return `${this.city}, ${this.state} - ${this.country}`;
  }

  public get imageLogoUrl(): string {
    return this.partnerLogo.replace('remateweb.s3.amazonaws.com', 'd13nls57a1x77f.cloudfront.net');
  }

  public get imageBannerUrl(): string {
    return this.partnerBanner.replace('remateweb.s3.amazonaws.com', 'd13nls57a1x77f.cloudfront.net');
  }

  public getVideoUrl(): string | undefined {
    return `https://www.youtube.com/embed/${this.partnerVideoId}?modestbranding=1`;
  }

  // public name: string;

  // public description: string;

  // @Type(() => Media)
  // public logo: Media;

  // @Type(() => Media)
  // public cover: Media;

  // @Type(() => Media)
  // public midia: Media[];

  // @Type(() => Info)
  // public infos: Info[];

  // @Type(() => Event)
  // public events: Event[];

  // @Type(() => Address)
  // public address: Address;
}
