import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ExternalLinkHelper {
  constructor(@Inject(PLATFORM_ID) private readonly platformId: any) {}
  public openInNewTab(url: string): void {
    if (isPlatformBrowser(this.platformId)) window.open(url, '_blank');
  }

  public openInTheSameTab(url: string): void {
    if (isPlatformBrowser(this.platformId)) window.location.href = url;
  }
}
