import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Type } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class ApiClient {
  constructor(private readonly httpClient: HttpClient) {}

  public get<M = any>(url: string, model?: Type<M>, params?: Record<string, any>): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl}/${url}`, { params }).pipe(
      map((data) => {
        if (!model) return data;
        return plainToClass(model, data);
      }),
    );
  }

  public post<M = any>(url: string, model?: Type<M>, data?: Record<string, any>): Observable<any> {
    return this.httpClient.post(`${environment.apiUrl}/${url}`, data).pipe(
      map((data) => {
        if (!model) return data;
        return plainToClass(model, data);
      }),
    );
  }
}
