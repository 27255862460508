import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BannerComponent } from './banner/banner.component';
import { PartnerCardComponent } from './partner/partner-card/partner-card.component';
import { ProductCardComponent } from './product/product-card/product-card.component';
import { EventCardComponent } from './event/event-card/event-card.component';
import { RouterModule } from '@angular/router';
import { Ng2FittextModule } from 'ng2-fittext';
import { TimerModule } from './timer/timer.module';
import { PipesModule } from '../core/pipes/pipes.module';

const components = [
  ProductCardComponent,
  BannerComponent,
  // PartnerCardComponent,
  ProductCardComponent,
  EventCardComponent,
];

@NgModule({
  declarations: components,
  imports: [CommonModule, RouterModule, TimerModule, PipesModule],
  exports: components,
})
export class ComponentsModule {}
