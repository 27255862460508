import { Model } from '../base.model';
import { Event } from '../event/event.model';
import { Type, Transform, Expose } from 'class-transformer';

export class LotImage {
  id: number;
  file: string;
  imageUrl: string;

  public get imageLotUrl(): string {
    return this.imageUrl.replace('remateweb.s3.amazonaws.com', 'd13nls57a1x77f.cloudfront.net');
  }
}

export class LotVideo {
  id: number;
  videoId: string;

  get thumbnail(): string {
    return `https://img.youtube.com/vi/${this.videoId}/0.jpg`;
  }

  get embedUrl(): string {
    return `https://www.youtube.com/embed/${this.videoId}?rel=0`;
  }
}

export type SaleType = 'shopping' | 'pre-bid';
export function intToSaleType(saleType: number): SaleType {
  return {
    2: 'shopping',
    3: 'pre-bid',
  }[saleType] as SaleType;
}
export function saleTypeToInt(saleType: SaleType): number {
  return {
    shopping: 2,
    'pre-bid': 3,
  }[saleType];
}

export class Product extends Model {
  auctionId: number;
  number: string;
  title: string;
  sellerTitle?: any;
  paymentConditions: string;
  paymentConditionObservation: string;
  nextBid: number;
  value: number;
  captation: number;
  quantity: number;
  city: string;
  state: string;
  description: string;
  unitValue: number;
  @Expose({ name: 'unityName' })
  unitName: string;
  totalValue: number;
  originalPrice: number;

  @Type(() => LotImage)
  lotImages: LotImage[];

  @Transform(({ value }) => intToSaleType(value))
  saleType: SaleType;
  sold: boolean;

  @Type(() => LotVideo)
  lotVideos: LotVideo[];

  public formatedCity(): string {
    return `${this.city}, ${this.state} - Brasil`;
  }

  get badge(): string {
    return {
      shopping: 'Shopping',
      'pre-bid': 'Pré-lance',
    }[this.saleType];
  }

  get valueTitle(): string {
    return {
      shopping: 'VALOR ATUAL',
      'pre-bid': 'LANCE ATUAL',
    }[this.saleType];
  }

  get defaultImage(): string {
    return (
      this.lotImages[0]?.imageLotUrl || 'https://via.placeholder.com/300x190?text=Remate Agroshop'
    );
  }

  isSold(event: Event): boolean {
    return this.saleType === 'shopping' ? this.sold : event.endDate < new Date();
  }
}
