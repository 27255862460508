import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ApiClient } from './api.client';
import { BannerProvider } from './banner/banner.provider';
import { ContactProvider } from './contact/contact.provider';
import { DealProvider } from './deal/deal.provider';
import { EventProvider } from './event/event.provider';
import { HomeProvider } from './home/home.provider';
import { LeadProvider } from './lead/lead.provider';
import { PartnerProvider } from './partner/partner.provider';
import { ProductProvider } from './product/product.provider';
import { SegmentProvider } from './segment/segment.provider';

const providers: any[] = [
  HttpClient,
  ApiClient,
  HomeProvider,
  SegmentProvider,
  PartnerProvider,
  EventProvider,
  BannerProvider,
  DealProvider,
  ContactProvider,
  LeadProvider,
  ProductProvider,
];

@NgModule({
  providers: [...providers],
})
export class DataModule {}
