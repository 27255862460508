import { ExternalLinkHelper } from '../../helpers/external-link';
import { Component, OnInit } from '@angular/core';

export type PageInfo = {
  title: string;
  route: string;
  url?: string;
};

export type InfoColumn = {
  title: string;
  pages: PageInfo[];
};

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public location = {
    city: 'Arapongas',
    address: 'Rodovia Br 369, Km 197 - Aricanduva',
    cep: '86700-970',
  };

  public contact = {
    phone: '(43) 3274-8585',
    fax: '(43) 3274-8500',
  };

  public infoColumns: InfoColumn[] = [
    {
      title: 'Plataforma',
      pages: [
        {
          title: 'Sobre a Remate Agroshop',
          route: '/sobre',
        },
        {
          title: 'Política de Privacidade',
          route: '/politica-de-privacidade',
        },
        {
          title: 'Cadastre-se',
          route: 'https://www.remateweb.com/cadastrar',
        },
        {
          title: 'Contato',
          route: '/contato',
        },
      ],
    },
    {
      title: 'Páginas',
      pages: [
        {
          title: 'Transmissões',
          route: 'https://www.remateweb.com/',
        },
        {
          title: 'Agenda',
          route: 'https://www.remateweb.com/agenda',
        },
        {
          title: 'Remate Play',
          route: 'https://www.remateweb.com/play',
        },
        {
          title: 'Parceiros Oficiais',
          route: '/parceiros',
        },
      ],
    },
  ];

  public socialMediaLinks = [
    {
      url: 'https://www.facebook.com/profile.php?id=61554898409693&mibextid=LQQJ4d',
      image: {
        src: 'assets/ic_facebook.png',
        alt: 'Facebook',
      },
    },
    {
      url: 'https://www.instagram.com/remate.agroshop?igsh=ZjVqNWJzN2owanh2',
      image: {
        src: 'assets/ic_instagram.png',
        alt: 'Instagram',
      },
    },
    {
      url: 'https://www.linkedin.com/company/remate-agroshop/',
      image: {
        src: 'assets/ic_linkedin.png',
        alt: 'Linkedin',
      },
    },
    {
      url: 'https://www.youtube.com/@remateagroshop',
      image: {
        src: 'assets/ic_youtube.png',
        alt: 'Youtube',
      },
    },
  ];

  public constructor(private externalLinkHelper: ExternalLinkHelper) {
    //
  }

  public ngOnInit(): void {
    //
  }

  public goTo(link: string): void {
    this.externalLinkHelper.openInNewTab(link);
  }
}
