import { Model } from '../base.model';
export class Banner extends Model {
  public filePathComplete: string;

  public image: string;

  public get imageUrl(): string {
    return `https://d13nls57a1x77f.cloudfront.net/${this.image}`;
  }
  
  public url: string;
}
