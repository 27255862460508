import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiClient } from '../api.client';
import { Home } from './home.model';

@Injectable()
export class HomeProvider {
  constructor(private readonly apiClient: ApiClient) {}

  public info(): Observable<Home> {
    return this.apiClient.get('Shopping', Home, {
      'vmFields.pageIndex': 1,
      'vmFields.pageSize': 100,
      'vmFields.orderBy': 'title',
      'vmFields.sortDirection': 0,
    });
  }
}
