import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiClient } from '../api.client';
import { Product } from './product.model';

@Injectable()
export class ProductProvider {
  constructor(private readonly apiClient: ApiClient) {}

  public getHighlightProducts(): Observable<Product[]> {
    return this.apiClient.get('lot/destaquehome', Product);
  }
}
