import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiClient } from '../api.client';
import { CreateLead, ICreateLead, ResponseCreateLead } from './lead.model';

@Injectable()
export class LeadProvider {
  constructor(private readonly apiClient: ApiClient, private readonly cookieService: CookieService) { }

  public create(data: ICreateLead): Observable<ResponseCreateLead> {
    const origem = this.cookieService.get('origin');
    return this.apiClient.post('AuctionInterest', ResponseCreateLead, plainToClass(CreateLead, {
      ...data, 
      origem: origem ? origem : undefined
    })).pipe(
      catchError((err) => {
        console.error(err);
        return of(err as ResponseCreateLead);
      }),
    );
  }
}
