import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiClient } from '../api.client';
import { Event } from './event.model';

@Injectable()
export class EventProvider {
  constructor(private readonly apiClient: ApiClient) {}

  public info(id: number): Observable<Event> {
    return this.apiClient.get('Shopping/' + id, Event);
  }

  public all(options: { partnerId?: string; categoryId?: number } = {}): Observable<Event[]> {
    const params: any = {
      'vmFields.userId': options.partnerId,
      'vmFields.auctionCategoryId': options.categoryId,
      'vmFields.pageIndex': 1,
      'vmFields.pageSize': 100,
      'vmFields.orderBy': 'title',
    };

    Object.keys(params).forEach((k) => params[k] == null && delete params[k]); // Remove undefined values

    return this.apiClient
      .get('Shopping', Event, params)
      .pipe(map((res) => plainToClass(Event, res.shoppings as any[])));
  }
}
