import { Transform } from 'class-transformer';
import { SaleType, saleTypeToInt } from '../product/product.model';

export interface ICreateDeal {
  lotId: string;
  dealType: SaleType;
  name: string;
  email: string;
  phoneNumber: string;
  recaptchaResponse: string;
  // document: string;
  // country: string;
  // state: string;
  // city: string;
  observations: string;
  value: number;
  extraFields: Record<string, string>
}

export class CreateDeal {
  lotId: string;
  @Transform(({ value }) => saleTypeToInt(value))
  dealType: number;
  name: string;
  email: string;
  phoneNumber: string;
  // country: string;
  // state: string;
  // city: string;
  observations: string;
  value: number;
  recaptchaResponse: string;
  extraFields: Record<string, string>
}

export class ResponseCreateDeal {
  success: boolean;
  message: string;
}
