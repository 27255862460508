import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiClient } from '../api.client';
import { Banner } from './banner.model';

@Injectable()
export class BannerProvider {
  constructor(private readonly apiClient: ApiClient) {}

  public getAll(): Observable<Banner[]> {
    return this.apiClient.get('banners-shopping/random', Banner);
  }
}
