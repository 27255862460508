import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiClient } from '../api.client';
import { CreateDeal, ICreateDeal, ResponseCreateDeal } from './deal.model';

@Injectable()
export class DealProvider {
  constructor(private readonly apiClient: ApiClient) { }

  public create(data: ICreateDeal): Observable<ResponseCreateDeal> {
    console.log(plainToClass(CreateDeal, data));
    return this.apiClient.post('Deal', ResponseCreateDeal, plainToClass(CreateDeal, data)).pipe(
      catchError((err) => {
        console.error(err);
        return of(err as ResponseCreateDeal);
      }),
    );
  }
}
