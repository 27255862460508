import { Expose, Transform } from 'class-transformer';
import { SaleType, saleTypeToInt } from '../product/product.model';

export interface ICreateLead {
  auctionId: number;
  email: string;
  name: string;
  phone: string;
}

export class CreateLead {
  auctionId: number;
  email: string;
  name: string;
  @Expose({ name: 'phone', toClassOnly: true })
  phoneNumber: string;
  recaptchaResponse: string = 'debug-AOOaTH9aOYfK9Xc_lkZ';
  origem?: string;
}

export class ResponseCreateLead { }
