import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './pages/about/about.component';
import { ContactComponent } from './pages/contact/contact.component';
import { EventDetailsComponent } from './pages/event/event-details/event-details.component';
import { EventListCategoryComponent } from './pages/event/event-list-category/event-list.component';
import { HomeComponent } from './pages/home/home.component';
import { PagesComponent } from './pages/pages.component';
import { PartnerDetailsComponent } from './pages/partner/partner-details/partner-details.component';
import { PartnerListComponent } from './pages/partner/partner-list/partner-list.component';
import { ProductDetailsComponent } from './pages/product/product-details/product-details.component';


const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'parceiros',
    loadChildren: () => import('./pages/partner/partner.module').then(m => m.PartnerModule)
  },
  {
    path: 'eventos',
    loadChildren: () => import('./pages/event/event.module').then(m => m.EventModule)
  },
  {
    path: 'categorias',
    loadChildren: () => import('./pages/event/event.module').then(m => m.EventListModule)
  },
  {
    path: 'sobre',
    loadChildren: () => import('./pages/about/about.module').then(m => m.AboutModule)
  },
  {
    path: 'politica-de-privacidade',
    loadChildren: () => import('./pages/policy/policy.module').then(m => m.PolicyModule)
  },
  {
    path: 'contato',
    // component: ContactComponent,
    loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [],
})
export class AppRoutingModule { }
