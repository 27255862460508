import { Expose, Transform } from 'class-transformer';

function map(name: string): string {
  return (
    {
      catalogo: 'catalogo.svg',
      file: 'file.svg',
      folder: 'folder.svg',
      imagem: 'image.svg',
      link: 'link.svg',
      pdf: 'pdf.svg',
      video: 'video.svg',
    }[name] || map('folder')
  );
}

export class AuctionLink {
  order: number;
  targetBlank: boolean;

  @Transform(({ value }) => map(value))
  @Expose({ name: 'cssclass' })
  imageName: string;

  title: string;
  url: string;
}
