<div class="footer mt-1 mt-sm-2">
  <section class="footer-content">
    <div class="row no-gutters justify-content-center align-items-center">
      <div class="col-2">
        <img
          src="assets/logos/logo_white.png"
          style="max-width: 75px"
          alt="Logo"
          [routerLink]="['/']"
        />
      </div>
      <div class="col-10">
        <div class="row no-gutters align-content-center">
          <div class="col-auto info-column" *ngFor="let info of infoColumns">
            <h3 class="info-title">
              {{ info.title }}
            </h3>
            <a *ngFor="let page of info.pages" [href]="page.route" target="_blank">
              {{ page.title }}
            </a>
          </div>
          <div class="col"></div>
        </div>
      </div>
    </div>
  </section>
  <div class="bottom-info">
    <section>
      <div class="row no-gutters justify-content-center">
        <div class="col align-self-center text-start">© Remate Agroshop 2024</div>
        <div class="col-auto">
          <button mat-button *ngFor="let link of socialMediaLinks" (click)="goTo(link.url)">
            <img [src]="link.image.src" [alt]="link.image.alt" />
          </button>
        </div>
      </div>
    </section>
  </div>
</div>
