import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { ApiClient } from '../api.client';
import { CreateContact, ICreateContact } from './contact.model';

@Injectable()
export class ContactProvider {
  constructor(private readonly apiClient: ApiClient) {}

  public create(data: ICreateContact): Observable<void> {
    return this.apiClient.post(
      'contact/send/anonymous',
      undefined,
      plainToClass(CreateContact, data),
    );
  }
}
