import { Transform } from 'class-transformer';
import { Model } from '../base.model';
import { Media } from '../media/media.model';
import { intToSaleType, Product, SaleType } from '../product/product.model';
import { Type } from 'class-transformer';
import { Partner } from '../partner/partner.model';
import { Segment } from '../segment/segment.model';
import { AuctionLink } from './auction-link.model';
import { ExtraField } from './extra-fields';

export class Event extends Model {
  @Type(() => Segment)
  public auctionCategory: Segment;

  @Type(() => AuctionLink)
  public auctionLinks: AuctionLink[];

  public title: string;

  public captation: number;

  public city: string;

  @Transform((value) => new Date(value.value))
  public date: Date;

  public description: string;

  public strippedDescription(): string {
    return this.description.replace(/<[^>]*>/g, '');
  }

  @Transform((value) => new Date(value.value))
  public endDate: Date;

  public hideTimers?: boolean = false;

  @Type(() => ExtraField)
  public dealExtraFields: ExtraField[]

  public imageComplete: string;

  public paymentConditions: string;

  public shoppingImage: string;

  public get imageUrl(): string {
    return `https://d13nls57a1x77f.cloudfront.net/${this.shoppingImage}`;
  }

  public state: string;

  public visible: boolean;

  @Transform(({ value }) => intToSaleType(value))
  saleType: SaleType;
  sold: boolean;

  @Type(() => Product)
  public lots: Product[];
}
