import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiClient } from '../api.client';
import { Partner } from './partner.model';

@Injectable()
export class PartnerProvider {
  constructor(private readonly apiClient: ApiClient) {}

  public info(id: number): Observable<Partner> {
    return this.apiClient.get(`ShoppingPartner/${id}`, Partner);
  }

  public all(options?: { segmentId?: number }): Observable<Partner[]> {
    const params: any = {
      'vmFields.pageIndex': 1,
      'vmFields.pageSize': 100,
      'vmFields.orderBy': 'title',
      'vmFields.auctionCategoryId': options?.segmentId,
    };

    Object.keys(params).forEach((k) => params[k] == null && delete params[k]); // Remove undefined values

    return this.apiClient
      .get('ShoppingPartner', Partner, params)
      .pipe(map((res) => plainToClass(Partner, res.partners as any[])));
  }
}
