import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { PipesModule } from "src/app/core/pipes/pipes.module";
import { TimerComponent } from "./timer.component";

@NgModule({
  declarations: [
    TimerComponent,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    TimerComponent
  ]
})
export class TimerModule { }