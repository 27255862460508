import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiClient } from '../api.client';
import { Segment, SegmentPartners } from './segment.model';

@Injectable()
export class SegmentProvider {
  constructor(private readonly apiClient: ApiClient) {}

  public all(): Observable<Segment[]> {
    return this.apiClient.get('auctioncategories?withEvents=true', Segment);
  }

  public info(id: string): Observable<Segment> {
    return this.apiClient.get('AuctionCategory/' + id, Segment);
  }

  public allWithPartners(): Observable<SegmentPartners[]> {
    return this.apiClient.get('auctioncategories/home', SegmentPartners);
  }
}
